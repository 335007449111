import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<WrapperProps>`
  ${tw`max-w-xl mx-auto md:px-3 px-2-4 pt-4-6 md:pt-7`}
  ${({ hasResults }) => (hasResults === "true" ? tw`pb-6 md:pb-8` : tw`pb-2-2 md:pb-0`)}
`

export const TitleWrapper = tw.div`
  mb-4-6 md:mb-0
`

export const Title = tw.h2`
  font-bold tracking-2 text-20 leading-1.25 mlg:text-50 mlg:leading-1.2 text-center md:text-left mlg:max-w-63 uppercase
`

export const Header = tw.section`
  flex flex-col items-center md:flex-row md:justify-between md:items-end md:pb-8 md:border-b md:border-black
`

export const Stats = tw.div`
  mb-1-8 md:mb-3-8 leading-1.78 text-14 text-center md:text-left
`

export const NoResults = tw.div`
  pb-3-6 md:pb-11 md:px-3 max-w-xl mx-auto 
`

export const Divider = tw.hr`
  w-full pb-3-2 md:pb-8
`

type WrapperProps = {
  hasResults: "true" | "false"
}
