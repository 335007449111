import React from "react"

import { useRoutes } from "../../hooks/useRoutes"

export const withSearch = Component => ({ name = "Search", page }) => {
  const { getUrlParameter } = useRoutes()

  const { featuredProductsTitle, featuredProductsLink, collection, products } = page

  const searchTerms = getUrlParameter("q")

  Component.displayName = name
  return (
    <Component
      searchTerms={searchTerms}
      featuredProductsTitle={featuredProductsTitle}
      featuredProductsLink={featuredProductsLink}
      collection={collection}
      products={products}
    />
  )
}
