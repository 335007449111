import React from "react"
import config from "../../../config.default"
import { ReactifySearchProvider, Sensors, Results, ResultStateProvider } from "@usereactify/search"

import { withSearch } from "./withSearch"
import { Wrapper, Title, Header, TitleWrapper, Stats, NoResults, Divider } from "./SearchStyles"
import { Card } from "../Product/Card/Card"
import { FeaturedProducts } from "../Sections/FeaturedProducts/FeaturedProducts"
import { SearchPagination } from "./Pagination/SearchPagination"
import { Callout } from "../Callout/Callout"

import { Wrapper as ResultsWrapper } from "../Search/Results/SearchResultStyles"

export const Search = withSearch(
  ({ searchTerms, searchResults, featuredProductsTitle, featuredProductsLink, collection, products }): JSX.Element => {
    const renderPagination = React.useCallback(props => {
      return <SearchPagination {...props} />
    }, [])
    const renderResultCardCallout = React.useCallback(props => {
      return <Callout {...props} />
    }, [])
    return (
    <ReactifySearchProvider mode="search" shopifyPermanentDomain={config?.stores?.[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]?.searchDomain}>
      <Sensors />
      <Wrapper hasResults={searchResults !== 0 ? "true" : "false"}>
        <ResultStateProvider
          render={({ hits }) => (
            <Stats>
              {hits?.total} {hits?.total === 1 ? "result" : "results"} found
            </Stats>
          )}
        />
        <Header>
          <TitleWrapper>
            <Title>Search results</Title>
            {searchTerms ? <Title>for &ldquo;{searchTerms}&rdquo;</Title> : null}
          </TitleWrapper>
        </Header>
        <ResultsWrapper>
          <Results
            renderResultCardProduct={props => {
              return <Card item={props?.product} {...props} />
            }}
            renderPagination={renderPagination}
            renderResultCardCallout={renderResultCardCallout}
          />
        </ResultsWrapper>
      </Wrapper>
      {searchResults === 0 ? (
        <NoResults>
          <Divider />
          <FeaturedProducts title={featuredProductsTitle} link={featuredProductsLink} collection={collection} products={products} />
        </NoResults>
      ) : null}
    </ReactifySearchProvider>
  )}
)
